var exports = {};

function _typeof(e) {
  return (_typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
    return typeof e;
  } : function (e) {
    return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
  })(e);
}

var isff = "undefined" != typeof navigator && 0 < navigator.userAgent.toLowerCase().indexOf("firefox");

function addEvent(e, o, t) {
  e.addEventListener ? e.addEventListener(o, t, !1) : e.attachEvent && e.attachEvent("on".concat(o), function () {
    t(window.event);
  });
}

function getMods(e, o) {
  for (var t = o.slice(0, o.length - 1), n = 0; n < t.length; n++) t[n] = e[t[n].toLowerCase()];

  return t;
}

function getKeys(e) {
  "string" != typeof e && (e = "");

  for (var o = (e = e.replace(/\s/g, "")).split(","), t = o.lastIndexOf(""); 0 <= t;) o[t - 1] += ",", o.splice(t, 1), t = o.lastIndexOf("");

  return o;
}

function compareArray(e, o) {
  for (var t = e.length < o.length ? o : e, n = e.length < o.length ? e : o, r = !0, s = 0; s < t.length; s++) ~n.indexOf(t[s]) || (r = !1);

  return r;
}

for (var _keyMap = {
  backspace: 8,
  tab: 9,
  clear: 12,
  enter: 13,
  return: 13,
  esc: 27,
  escape: 27,
  space: 32,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  del: 46,
  delete: 46,
  ins: 45,
  insert: 45,
  home: 36,
  end: 35,
  pageup: 33,
  pagedown: 34,
  capslock: 20,
  "\u21EA": 20,
  ",": 188,
  ".": 190,
  "/": 191,
  "`": 192,
  "-": isff ? 173 : 189,
  "=": isff ? 61 : 187,
  ";": isff ? 59 : 186,
  "'": 222,
  "[": 219,
  "]": 221,
  "\\": 220
}, _modifier = {
  "\u21E7": 16,
  shift: 16,
  "\u2325": 18,
  alt: 18,
  option: 18,
  "\u2303": 17,
  ctrl: 17,
  control: 17,
  "\u2318": 91,
  cmd: 91,
  command: 91
}, modifierMap = {
  16: "shiftKey",
  18: "altKey",
  17: "ctrlKey",
  91: "metaKey",
  shiftKey: 16,
  ctrlKey: 17,
  altKey: 18,
  metaKey: 91
}, _mods = {
  16: !1,
  18: !1,
  17: !1,
  91: !1
}, _handlers = {}, k = 1; k < 20; k++) _keyMap["f".concat(k)] = 111 + k;

var _downKeys = [],
    _scope = "all",
    elementHasBindEvent = [],
    code = function (e) {
  return _keyMap[e.toLowerCase()] || _modifier[e.toLowerCase()] || e.toUpperCase().charCodeAt(0);
};

function setScope(e) {
  _scope = e || "all";
}

function getScope() {
  return _scope || "all";
}

function getPressedKeyCodes() {
  return _downKeys.slice(0);
}

function filter(e) {
  var o = e.target || e.srcElement,
      t = o.tagName,
      n = !0;
  return !o.isContentEditable && ("INPUT" !== t && "TEXTAREA" !== t || o.readOnly) || (n = !1), n;
}

function isPressed(e) {
  return "string" == typeof e && (e = code(e)), !!~_downKeys.indexOf(e);
}

function deleteScope(e, o) {
  var t, n;

  for (var r in e = e || getScope(), _handlers) if (Object.prototype.hasOwnProperty.call(_handlers, r)) for (t = _handlers[r], n = 0; n < t.length;) t[n].scope === e ? t.splice(n, 1) : n++;

  getScope() === e && setScope(o || "all");
}

function clearModifier(e) {
  var o = e.keyCode || e.which || e.charCode,
      t = _downKeys.indexOf(o);

  if (t < 0 || _downKeys.splice(t, 1), e.key && "meta" == e.key.toLowerCase() && _downKeys.splice(0, _downKeys.length), 93 !== o && 224 !== o || (o = 91), o in _mods) for (var n in _mods[o] = !1, _modifier) _modifier[n] === o && (hotkeys[n] = !1);
}

function unbind(e) {
  if (e) {
    if (Array.isArray(e)) e.forEach(function (e) {
      e.key && eachUnbind(e);
    });else if ("object" === _typeof(e)) e.key && eachUnbind(e);else if ("string" == typeof e) {
      for (var o = arguments.length, t = Array(1 < o ? o - 1 : 0), n = 1; n < o; n++) t[n - 1] = arguments[n];

      var r = t[0],
          s = t[1];
      "function" == typeof r && (s = r, r = ""), eachUnbind({
        key: e,
        scope: r,
        method: s,
        splitKey: "+"
      });
    }
  } else Object.keys(_handlers).forEach(function (e) {
    return delete _handlers[e];
  });
}

var eachUnbind = function (e) {
  var i = e.scope,
      d = e.method,
      o = e.splitKey,
      a = void 0 === o ? "+" : o;
  getKeys(e.key).forEach(function (e) {
    var o = e.split(a),
        t = o.length,
        n = o[t - 1],
        r = "*" === n ? "*" : code(n);

    if (_handlers[r]) {
      i = i || getScope();
      var s = 1 < t ? getMods(_modifier, o) : [];
      _handlers[r] = _handlers[r].map(function (e) {
        return (!d || e.method === d) && e.scope === i && compareArray(e.mods, s) ? {} : e;
      });
    }
  });
};

function eventHandler(e, o, t) {
  var n;

  if (o.scope === t || "all" === o.scope) {
    for (var r in n = 0 < o.mods.length, _mods) Object.prototype.hasOwnProperty.call(_mods, r) && (!_mods[r] && ~o.mods.indexOf(+r) || _mods[r] && !~o.mods.indexOf(+r)) && (n = !1);

    (0 !== o.mods.length || _mods[16] || _mods[18] || _mods[17] || _mods[91]) && !n && "*" !== o.shortcut || !1 === o.method(e, o) && (e.preventDefault ? e.preventDefault() : e.returnValue = !1, e.stopPropagation && e.stopPropagation(), e.cancelBubble && (e.cancelBubble = !0));
  }
}

function dispatch(t) {
  var e = _handlers["*"],
      o = t.keyCode || t.which || t.charCode;

  if (hotkeys.filter.call(this, t)) {
    if (93 !== o && 224 !== o || (o = 91), ~_downKeys.indexOf(o) || 229 === o || _downKeys.push(o), ["ctrlKey", "altKey", "shiftKey", "metaKey"].forEach(function (e) {
      var o = modifierMap[e];
      t[e] && !~_downKeys.indexOf(o) ? _downKeys.push(o) : !t[e] && ~_downKeys.indexOf(o) && _downKeys.splice(_downKeys.indexOf(o), 1);
    }), o in _mods) {
      for (var n in _mods[o] = !0, _modifier) _modifier[n] === o && (hotkeys[n] = !0);

      if (!e) return;
    }

    for (var r in _mods) Object.prototype.hasOwnProperty.call(_mods, r) && (_mods[r] = t[modifierMap[r]]);

    var s = getScope();
    if (e) for (var i = 0; i < e.length; i++) e[i].scope === s && ("keydown" === t.type && e[i].keydown || "keyup" === t.type && e[i].keyup) && eventHandler(t, e[i], s);
    if (o in _handlers) for (var d = 0; d < _handlers[o].length; d++) if (("keydown" === t.type && _handlers[o][d].keydown || "keyup" === t.type && _handlers[o][d].keyup) && _handlers[o][d].key) {
      for (var a = _handlers[o][d], c = a.key.split(a.splitKey), f = [], l = 0; l < c.length; l++) f.push(code(c[l]));

      f.sort().join("") === _downKeys.sort().join("") && eventHandler(t, a, s);
    }
  }
}

function isElementBind(e) {
  return !!~elementHasBindEvent.indexOf(e);
}

function hotkeys(e, o, t) {
  _downKeys = [];
  var n = getKeys(e),
      r = [],
      s = "all",
      i = document,
      d = 0,
      a = !1,
      c = !0,
      f = "+";

  for (void 0 === t && "function" == typeof o && (t = o), "[object Object]" === Object.prototype.toString.call(o) && (o.scope && (s = o.scope), o.element && (i = o.element), o.keyup && (a = o.keyup), void 0 !== o.keydown && (c = o.keydown), "string" == typeof o.splitKey && (f = o.splitKey)), "string" == typeof o && (s = o); d < n.length; d++) r = [], 1 < (e = n[d].split(f)).length && (r = getMods(_modifier, e)), (e = "*" === (e = e[e.length - 1]) ? "*" : code(e)) in _handlers || (_handlers[e] = []), _handlers[e].push({
    keyup: a,
    keydown: c,
    scope: s,
    mods: r,
    shortcut: n[d],
    method: t,
    key: n[d],
    splitKey: f
  });

  void 0 !== i && !isElementBind(i) && window && (elementHasBindEvent.push(i), addEvent(i, "keydown", function (e) {
    dispatch(e);
  }), addEvent(window, "focus", function () {
    _downKeys = [];
  }), addEvent(i, "keyup", function (e) {
    dispatch(e), clearModifier(e);
  }));
}

var _api = {
  setScope: setScope,
  getScope: getScope,
  deleteScope: deleteScope,
  getPressedKeyCodes: getPressedKeyCodes,
  isPressed: isPressed,
  filter: filter,
  unbind: unbind
};

for (var a in _api) Object.prototype.hasOwnProperty.call(_api, a) && (hotkeys[a] = _api[a]);

if ("undefined" != typeof window) {
  var _hotkeys = window.hotkeys;
  hotkeys.noConflict = function (e) {
    return e && window.hotkeys === hotkeys && (window.hotkeys = _hotkeys), hotkeys;
  }, window.hotkeys = hotkeys;
}

exports = hotkeys;
export default exports;